import Search from '@ingka/search';
import React, { useEffect, useRef, useState } from 'react';
import { Section } from './components/section/section';
import { SectionWrap } from './SectionWrap';
import { Loadable } from './util/type';
import InlineMessage from '@ingka/inline-message';
import Label from '@ingka/label';

import cameraIcon from '@ingka/ssr-icon/paths/camera';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Checkbox from '@ingka/checkbox';
import { FindSimilarFilter, FindSimilarPayload, FindSimilarResponse } from './util/find-similar';
import { ClipSearchPayload, ClipSearchResponse } from './util/clip-search';
import { BynderBoardInput } from './components/bynder-board-input/BynderBoardInput';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { SimilarList } from './components/similar-list';
import { areArraysSortedEqual } from './helpers/arrays';
import { SimilarityFilter } from './components/similarity-filter';

// taken from https://sadique.io/blog/2013/10/03/fitting-an-image-in-to-a-canvas-object/
/**
 * Fit image to canvas, keeping aspect ratio. Similar to object-fit: contain in css.
 * It's also what we do in the backend with sharp.
 */
function fitImageToCanvas(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  imageObj: HTMLImageElement,
) {
  const imageAspectRatio = imageObj.width / imageObj.height;
  const canvasAspectRatio = canvas.width / canvas.height;
  let renderableHeight, renderableWidth, xStart, yStart;

  // If image's aspect ratio is less than canvas's we fit on height
  // and place the image centrally along width
  if (imageAspectRatio < canvasAspectRatio) {
    renderableHeight = canvas.height;
    renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
    xStart = (canvas.width - renderableWidth) / 2;
    yStart = 0;
  }

  // If image's aspect ratio is greater than canvas's we fit on width
  // and place the image centrally along height
  else if (imageAspectRatio > canvasAspectRatio) {
    renderableWidth = canvas.width;
    renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
    xStart = 0;
    yStart = (canvas.height - renderableHeight) / 2;
  }

  // Happy path - keep aspect ratio
  else {
    renderableHeight = canvas.height;
    renderableWidth = canvas.width;
    xStart = 0;
    yStart = 0;
  }
  ctx.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
}

function resizeImage(
  file: File,
  maxWidth = 400,
  maxHeight = 350,
): Promise<{ base64: string; blob: Blob } | false> {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = maxWidth;
      canvas.height = maxHeight;

      const ctx = canvas.getContext('2d');

      if (ctx === null) {
        console.log('Could not get 2d context');
        resolve(false);
        return;
      }

      fitImageToCanvas(canvas, ctx, img);

      canvas.toBlob((d) => {
        if (d === null) {
          resolve(false);
        } else {
          resolve({ base64: canvas.toDataURL(), blob: d });
        }
      });
    };
  });
}

interface SimilaritySearchProps {
  findSimilar: (payload: FindSimilarPayload) => Promise<FindSimilarResponse>;
  clipSearch: (payload: ClipSearchPayload) => Promise<ClipSearchResponse>;
  bynderBaseUrl: string;
  usageRights: string[];
  requestingMarket: string | undefined;
}

export function SimilaritySearchPage(props: SimilaritySearchProps): JSX.Element {
  const [text, setText] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('text') || '';
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const [similar, setSimilar] = useState<Loadable<FindSimilarResponse>>({
    status: 'uninitialized',
  });
  const [similarBaseImage, setSimilarBaseImage] = useState<
    null | { base64: string; blob: Blob; type: 'base64' } | { url: string; type: 'url' }
  >(null);

  const [inputError, setInputError] = useState<null | { error: 'invalid-url' }>(null);

  const [filter, setFilter] = useState<FindSimilarFilter>({
    key: '',
    value: '',
    valid: false,
  });
  const [onlyShoppable, setOnlyShoppable] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [assetsInBoard, setAssetsInBoard] = useState<string[]>([]);

  const navigate = useNavigate();

  const doSearch = async () => {
    const searchString = text.trim();
    const isHttpSearch = searchString.startsWith('http');

    if (isHttpSearch) {
      try {
        new URL(text);
      } catch (_) {
        setInputError({ error: 'invalid-url' });
        return;
      }
    }

    setInputError(null);
    setSelectedIds([]);

    setSimilar({ status: 'loading' });

    let result = null;

    if (isHttpSearch) {
      setSimilarBaseImage({ url: text, type: 'url' });
      result = await props.findSimilar({
        imageUrl: text,
        type: 'url',
        count: 20,
      });
    } else {
      if (searchString.length === 0) {
        setSimilar({ status: 'uninitialized' });
        return;
      }

      navigate({
        search: createSearchParams({ text: text }).toString(),
      });

      result = await props.clipSearch({
        text: searchString,
        count: 100,
        type: 'text',
        filter,
      });
    }

    setSimilar({ status: 'loaded', data: result });
  };

  // on mount, execute a search
  useEffect(() => {
    void doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUploadFileChanged = () => {
    if (!fileRef.current || !fileRef.current.files || fileRef.current.files.length === 0) {
      return;
    }

    const file = fileRef.current.files[0];

    resizeImage(file, 380, 380)
      .then((d) => {
        if (d) {
          setInputError(null);
          setSimilarBaseImage({ type: 'base64', ...d });
          setSimilar({ status: 'loading' });
          props
            .findSimilar({ base64: d.base64, type: 'base64', count: 20 })
            .then((q) => {
              setSimilar({ status: 'loaded', data: q });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const searchActions = [
    {
      ssrIcon: cameraIcon,
      onClick: () => {
        if (fileRef.current) {
          fileRef.current.click();
        }
      },
    },
  ];

  let img;
  if (similarBaseImage !== null) {
    img = (
      <div
        css={css`
          margin-bottom: 40px;
        `}
      >
        <img
          src={
            similarBaseImage.type === 'base64'
              ? URL.createObjectURL(similarBaseImage.blob)
              : similarBaseImage.url
          }
          style={{ objectFit: 'contain' }}
          alt="similar base image"
          width="380"
          height="380"
        />
      </div>
    );
  }

  let allSelected = false;
  if (similar.status === 'loaded' && similar.data) {
    allSelected = areArraysSortedEqual(
      similar.data.similar.map((item) => item.id),
      selectedIds,
    );
  }

  const handleSelectAll = () => {
    if (similar.status === 'loaded' && similar.data) {
      if (allSelected) {
        setSelectedIds([]);
      } else {
        setSelectedIds(similar.data.similar.map(({ id }) => id));
      }
    }
  };

  let inputErrorRendering;
  if (inputError !== null) {
    inputErrorRendering = <InlineMessage variant={'negative'} title={'Invalid URL'} />;
  }

  return (
    <SectionWrap>
      <Section size="large">
        <Section>
          <InlineMessage
            variant="cautionary"
            title={'Note'}
            body={
              <p>
                Keep in mind that results on this page are completely unfiltered, may contain
                archived, usage right restricted or limited assets.
              </p>
            }
          />
        </Section>
      </Section>
      <Section size="small">
        <Label
          css={css`
            margin-bottom: 10px;
            display: block;
          `}
          htmlFor="search"
          label={
            <>
              <span>
                Enter a text for textual image search. Image url or use the camera to search for
                similar images.
              </span>
            </>
          }
        />
        <Search
          id="search"
          placeholder=""
          value={text}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setText(e.currentTarget.value)}
          onSearch={doSearch}
          actions={searchActions}
        />
        <input
          type="file"
          multiple={false}
          ref={fileRef}
          style={{ display: 'none' }}
          onChange={onUploadFileChanged}
        />
        <div
          css={css`
            padding: 20px;
            display: none;
          `}
        >
          <Checkbox
            id={'search_only_shoppable'}
            label={'Only shoppable'}
            value={''}
            onClick={() => setOnlyShoppable(!onlyShoppable)}
          />
        </div>
      </Section>
      {inputErrorRendering}
      <SimilarityFilter filter={filter} onChange={setFilter} />
      <Section size="large">
        <BynderBoardInput
          onAssetsAddedToBoard={() => {
            setSelectedIds([]);
          }}
          selectedAssetIds={selectedIds}
          onBoardStatusChange={(status) => {
            if (status.status === 'loaded' && status.data.type === 'collection-result') {
              setAssetsInBoard(status.data.assets);
            }
          }}
          allSelected={allSelected}
          toggleSelectAll={handleSelectAll}
        />
      </Section>

      <Section>
        {img}
        <div>
          <SimilarList
            similar={similar}
            selectedIds={selectedIds}
            onSelectedIdsChanged={(ids) => {
              setSelectedIds(ids);
            }}
            bynderBaseUrl={props.bynderBaseUrl}
            boardAssetIds={assetsInBoard}
          />
        </div>
      </Section>

      <div
        css={css`
          margin-bottom: '10vh';
        `}
      ></div>
    </SectionWrap>
  );
}
