/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext, useReducer, useState } from 'react';
import { Route, NavLink, Link, Routes, useNavigate } from 'react-router-dom';
import logo from './ikea-logo.svg';
import { ladanBackendScope } from './util/authConfig';
import { MsalProvider, useAccount } from '@azure/msal-react';
import Button from '@ingka/button';
import { Deliveries } from './Deliveries';
import { AssetEdit } from './AssetEdit';
import { SectionWrap } from './SectionWrap';
import { safeExtractResponseJson } from './util/common';
import {
  UploadJobItem,
  EditJobItem,
  BynderAssetUploadJob,
  CreatePackageBodyItem,
  CreateBynderAssetJobResult,
  SearchRequestBody,
  ExtRequestParams,
  PIAItemIdentifier,
  IDAMAssetInterface,
} from './generated-backend-api/index';
import { env, bynder } from './config';
import { PHTools } from './PHTools';
import { AssetStatus } from './AssetStatus';
import { Section } from './components/section/section';
import { useBynder } from './util/bynderReactUtils';
import { Upload } from './Upload';
import { useDebouncedCallback } from 'use-debounce';
import { useToasts } from './util/toastProvider';
import { SimilaritySearchPage } from './Similarity';
import { checkBynderMeta } from './util/idam-bynder-meta-translation';
import { getInitialState, stateReducer } from './app-state-reducer';

import person from '@ingka/ssr-icon/paths/person';
import { ProfileModal } from './components/profile-modal';
import { Crop } from './pages/Crop/Crop';
import { CropBoardsPage } from './pages/CropBoardsPage';
import {
  AccountInfo,
  BrowserAuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { CustomNavigationClient } from './util/NavigationClient';
import { findSimilar, FindSimilarPayload, FindSimilarResponse } from './util/find-similar';
import PQueue from 'p-queue';
import { UploadJobItemWithThumbnail } from './util/common-types';
import { clipSearch, ClipSearchPayload } from './util/clip-search';
import { useIsDamUser } from './hooks/useIsDamUser';
import CropIndexPage from './pages/CropIndexPage';
import SearchPage from './pages/SearchPage';
import { CropBoardPage } from './pages/CropBoardPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useClient } from './hooks/api-client';

interface IAppProps {
  logout: () => void;
}

// main entrypoint
function App(props: IAppProps) {
  const [showProfile, setShowProfile] = useState(false);

  const bynderWrapper = useBynder();

  const account = useAccount();

  const isDAMUser = useIsDamUser();

  const [state, dispatch] = useReducer(stateReducer, getInitialState());

  const bynderInstance = useBynder();
  const navigate = useNavigate();

  const toastsApi = useToasts();

  const client = useClient();

  const loadUploadsList = useDebouncedCallback(async (startBefore?: number) => {
    let prev;

    if (
      state.uploadsList.status === 'loaded' &&
      state.uploadsList.data.type === 'composite-uploads-list-result'
    ) {
      prev = state.uploadsList.data.result;
    }

    dispatch({ type: 'uploads-list-loading', prev, startBefore });
    const list = await client.GetBynderAssetUploadJobList({
      bynderAssetUploadJobListRequestBody: { startBefore },
    });
    dispatch({ type: 'uploads-list-loaded', result: list, startBefore });
  }, 300);

  const loadEditList = useDebouncedCallback(async (startBefore?: number) => {
    let prev;

    if (
      state.edit.editList.status === 'loaded' &&
      state.edit.editList.data.type === 'composite-edit-list-result'
    ) {
      prev = state.edit.editList.data.result;
    }

    dispatch({ type: 'edit-list-loading', prev, startBefore });
    const list = await client.GetBynderAssetEditJobList({
      bynderAssetEditJobListRequestBody: { startBefore },
    });
    dispatch({ type: 'edit-list-loaded', result: list, startBefore });
  }, 300);

  const loadBynderMeta = useDebouncedCallback(async () => {
    dispatch({ type: 'bynder-meta-loading' });
    const list = await client.BynderMeta({});
    checkBynderMeta(list);
    dispatch({ type: 'bynder-meta-loaded', result: list });
  }, 100);

  const uploadSearchFS = useDebouncedCallback(async (phs: string[]) => {
    dispatch({ type: 'upload-search-fs-loading', phs });
    const res = await client.SearchFS({
      fSSearchRequestBody: { phList: phs },
    });
    dispatch({ type: 'upload-search-fs-loaded', result: res, phs });
  }, 500);

  const search2ExtApi = useDebouncedCallback(async (opts: SearchRequestBody) => {
    dispatch({ type: 'search2-extapi-loading', options: opts });
    const res = await client.SearchExtApi2({
      extApiSearch2RequestBody: { options: opts },
    });
    dispatch({ type: 'search2-extapi-loaded', result: res, options: opts });
  }, 100);

  const searchCropPage = useDebouncedCallback(async (opts: SearchRequestBody) => {
    dispatch({ type: 'search-crop-page-loading', options: opts });
    const res = await client.SearchExtApi2({
      extApiSearch2RequestBody: { options: opts },
    });
    dispatch({ type: 'search-crop-page-loaded', result: res, options: opts });
  }, 100);

  const { getAccessToken } = useContext(ApiContext);

  const handleLoginClick = () => {
    void getAccessToken();
  };

  const Home: React.FunctionComponent<{ children: React.ReactNode }> = (p) => {
    return (
      <SectionWrap WrapperElement="main">
        <Section size="large">
          <h1>
            Welcome to Verktygsladan {env !== 'production' ? `(${String(env).toUpperCase()})` : ''}
          </h1>
        </Section>
        {p.children}
      </SectionWrap>
    );
  };

  if (account === null) {
    return (
      <Home>
        <Button onClick={() => handleLoginClick()}>Login</Button>
      </Home>
    );
  }

  const prepareForUpload = (jobs: UploadJobItemWithThumbnail[]) => {
    dispatch({ type: 'set-upload-jobs', jobs });

    navigate('/upload');
    window.scrollTo({ top: 0 });
  };

  const loadDeliveries = async (skipCache: boolean) => {
    console.log('load deliveries');
    if (state.deliveries.deliveriesList.status === 'loading') {
      console.log('already loading deliveries');
      return;
    }
    dispatch({ type: 'deliveries-loading' });
    const deliveries = await client.IMCCDeliveries({
      iMCCDeliveriesRequestBody: { skipCache },
    });
    dispatch({ type: 'deliveries-loaded', result: deliveries });
  };

  const loadMoreDeliveries = async () => {
    if (
      state.deliveries.deliveriesList.status !== 'loaded' ||
      state.deliveries.deliveriesList.data.type !== 'deliveries-list-response'
    ) {
      return;
    }

    const offset = state.deliveries.deliveriesList.data.result.nextOffset;
    const items = state.deliveries.deliveriesList.data.result.items;

    dispatch({ type: 'deliveries-loading' });

    const deliveries = await client.IMCCDeliveries({
      iMCCDeliveriesRequestBody: { skipCache: true, offset },
    });

    if (deliveries.type === 'deliveries-list-response') {
      deliveries.result.items = [...items, ...deliveries.result.items];

      dispatch({ type: 'deliveries-loaded', result: deliveries });
    }
  };

  const loadDelivery = async (id: string, skipCache: boolean) => {
    if (state.deliveries.deliveryDataMap[id]?.status === 'loading') {
      console.log(`Already loading delivery ${id}`);
      return;
    }

    console.log(`load delivery ${id}`);
    dispatch({ type: 'delivery-loading', id });
    const delivery = await client.IMCCDelivery({
      iMCCDeliveryRequestBody: { id, skipCache },
    });
    dispatch({ type: 'delivery-loaded', result: delivery, id });
  };

  const triggerDownloadPackage = async (downloadables: CreatePackageBodyItem[]) => {
    const res = await client.CreateDownloadPackage({
      createPackageBody: { assetsToDownload: downloadables },
    });

    if (res.type === 'create-package-response') {
      window.open(`/get-package/${res.packageId}`, '_blank');
    } else {
      alert('could not create package.');
    }
  };

  const loadDeliveryFSAssets = async (names: string[], deliveryId: string) => {
    if (state.deliveries.deliveryFsAssets.status === 'loading') {
      console.log('Already loading assets.');
      return;
    }

    dispatch({ type: 'delivery-fs-assets-loading', deliveryId, names });
    const res = await client.SearchFS({ fSSearchRequestBody: { phList: names } });
    dispatch({ type: 'delivery-fs-assets-loaded', names, result: res, deliveryId });
  };

  const toolsSearch = async (names: string[], includeSuffixResults: boolean) => {
    dispatch({ type: 'tools-assets-loading', names });

    const [fs, idam] = await Promise.all([
      client.SearchFS({
        fSSearchRequestBody: {
          phList: names,
          full: false,
          prefixSearch: includeSuffixResults,
        },
      }),
      await Promise.all(
        names.map((id) =>
          client.IDAMAsset({
            iDAMAssetRequestBody: { id },
          }),
        ),
      ),
    ]);

    const assets = idam
      .map((res) => (!res.isError ? res.result : undefined))
      .filter((res): res is IDAMAssetInterface => res !== undefined);

    dispatch({
      type: 'tools-assets-loaded',
      names,
      result: {
        fsAssets: fs,
        idamAssets: {
          result: assets,
          isError: false,
          type: 'idam-asset-result',
        },
      },
    });
  };

  const assetStatusSearchExtApi = async (opts: ExtRequestParams) => {
    dispatch({ type: 'asset-status-search-extapi-loading', options: opts });
    const req = await client.searchExtApiRaw({
      extApiSearchRequestBody: { options: opts },
    });
    const resClone = req.raw.clone();
    dispatch({
      type: 'asset-status-search-extapi-loaded',
      result: await req.value(),
      options: opts,
      raw: await safeExtractResponseJson(resClone),
    });
  };

  const searchFsFull = async (names: string[]) => {
    dispatch({ type: 'search-fs-full-loading', phs: names });
    const req = await client.searchFSRaw({
      fSSearchRequestBody: { phList: names, full: true },
    });

    const resClone = req.raw.clone();
    const res = await req.value();

    dispatch({
      type: 'search-fs-full-loaded',
      result: res,
      phs: names,
      raw: await safeExtractResponseJson(resClone),
    });
    const ids: string[] = [];
    if (
      res.type === 'fs-search-result-prod-response' ||
      res.type === 'fs-search-result-dev-response'
    ) {
      for (const i of res.results) {
        ids.push(i.id);
      }
    }
    console.log('IDS', ids);
    console.log('Full', res);

    return ids;
  };

  const editSearchFsByIds = async (ids: string[]) => {
    dispatch({ type: 'edit-search-fs-loading', ids });
    const res = await client.SearchFSByID({
      fSSearchRequestBodyID: { IDList: ids, full: true },
    });
    dispatch({ type: 'edit-search-fs-loaded', result: res, ids });
  };

  const searchFsByExtName = async (names: string[], extensions: string[]) => {
    dispatch({ type: 'search-name-ext-fs-loading', names, extensions });
    const res = await client.SearchFSExtName({
      fSSearchByExtNameRequestBody: { phList: names, extensions: extensions, full: true },
    });
    dispatch({ type: 'search-name-ext-fs-loaded', result: res, names, extensions });
  };

  const uploadSearchIDAMImage = async (phs: string[]) => {
    dispatch({ type: 'upload-search-idam-image-loading', phs });

    const idam = await Promise.all(
      phs.map(async (ph) => {
        const response = await client.IDAMAsset({
          iDAMAssetRequestBody: { id: ph },
        });

        return response;
      }),
    );

    const assets = idam
      .map((res) => (!res.isError ? res.result : undefined))
      .filter((res): res is IDAMAssetInterface => res !== undefined);

    dispatch({
      type: 'upload-search-idam-image-loaded',
      result: {
        isError: false,
        type: 'idam-asset-result',
        result: assets,
      },
      phs,
    });
  };

  const getImccImage = async (names: string[]) => {
    dispatch({ type: 'imcc-image-loading', phs: names });
    const req = await client.searchIMCCImageRaw({
      iMCCImageSearchRequestBody: { phList: names },
    });
    const resClone = req.raw.clone();

    dispatch({
      type: 'imcc-image-loaded',
      result: await req.value(),
      phs: names,
      raw: await safeExtractResponseJson(resClone),
    });
  };

  const retryUploadJob = async (job: BynderAssetUploadJob) => {
    if (!bynderInstance.isLoggedIn) {
      toastsApi.push({
        isError: true,
        message: 'Not logged in to bynder. Login and try again.',
      });
      return;
    }

    const token = await bynderInstance.getToken(true);

    if (token === null) {
      toastsApi.push({
        isError: true,
        message: 'Could not refresh access token.',
      });
      return;
    }

    if (state.uploadRetryMap[job.id]) {
      toastsApi.push({
        isError: true,
        message: 'Could not retry job, already retrying.',
      });
      return;
    }

    dispatch({ type: 'retry-upload-loading', id: job.id });

    const item = await client.SearchFS({
      fSSearchRequestBody: { phList: [job.item.name] },
    });

    if (item.isError) {
      toastsApi.push({
        isError: true,
        message: `Could not retry job, unable to check if '${job.item.name}' already exists. Error: ${item.type}`,
      });
      dispatch({ type: 'retry-upload-loaded', id: job.id, result: undefined });
      return;
    }

    if (item.results.length === 1) {
      console.log(item);
      const r = confirm(
        `'${job.item.name}' already exists in dam, are you sure you want to upload it again?`,
      );

      if (!r) {
        toastsApi.push({
          isError: false,
          message: `Retry for '${job.item.name}' cancelled.`,
        });
        dispatch({ type: 'retry-upload-loaded', id: job.id, result: undefined });
        return;
      }
    }

    dispatch({ type: 'retry-upload-loaded', id: job.id, result: undefined });

    const retryResult = await client.BynderAssetJobRetry({
      bynderAssetJobRetryRequestBody: {
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        id: job.id,
      },
    });

    if (retryResult.isError) {
      toastsApi.push({
        isError: true,
        message: `Could not retry job for item '${job.item.name}'. Error: ${retryResult.type}`,
      });
      return;
    }

    toastsApi.push({
      isError: false,
      message: 'Retry queued.',
    });

    dispatch({ type: 'retry-upload-loaded', id: job.id, result: retryResult });
  };

  const searchBynder = async (ids: string[]) => {
    if (bynderWrapper.isLoggedIn) {
      dispatch({ type: 'search-bynder-loading', ids });
      const resPromise = [];
      for (const id of ids) {
        resPromise.push(bynderWrapper.bynder.getAsset(id));
      }
      const res = await Promise.all(resPromise);
      dispatch({
        type: 'search-bynder-loaded',
        result: res,
        ids: ids,
      });
      console.log('Bynder', res);
    }
  };

  const editBynderAsset = async (
    items: EditJobItem[],
  ): Promise<CreateBynderAssetJobResult | false> => {
    if (!bynderInstance.isLoggedIn) {
      return false;
    }

    const token = await bynderInstance.getToken(true);

    if (token === null) {
      return false;
    }

    const r = await client.CreateBynderAssetJob({
      createBynderJobRequestBody: {
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        items,
      },
    });

    return r;
  };

  const createUploadAssetToBynderJob = async (
    items: UploadJobItem[],
  ): Promise<CreateBynderAssetJobResult | false> => {
    if (!bynderInstance.isLoggedIn) {
      return false;
    }

    const token = await bynderInstance.getToken(true);

    if (token === null) {
      return false;
    }

    const r = await client.CreateBynderAssetJob({
      createBynderJobRequestBody: {
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        items,
      },
    });

    return r;
  };

  /**
   * Runs findSimilar on all payloads concurrently 3 at a time.
   */
  const findSimilarImages = async (
    payloads: FindSimilarPayload[],
    onProgress?: (percentage: number) => void,
  ): Promise<FindSimilarResponse[]> => {
    const out: [number, FindSimilarResponse][] = [];
    const queue = new PQueue({ concurrency: 3 });

    let counter = 0;
    if (onProgress) {
      onProgress(0);
    }

    const promises = payloads.map((p, i) => {
      return queue.add(async () => {
        const r = await findSimilar(p, client);
        out.push([i, r]);
        counter += 1;
        if (onProgress) {
          onProgress(counter / payloads.length);
        }
      });
    });

    await Promise.all(promises);

    return out.sort((a, b) => a[0] - b[0]).map((a) => a[1]);
  };

  const activeClass = (className: string) => {
    return ({ isActive }: { isActive: boolean }) => {
      return isActive ? className : '';
    };
  };

  const assetStatus = (
    <AssetStatus
      searchFs={searchFsFull}
      searchFsResults={state.assetStatus.searchFsFull}
      searchImcc={getImccImage}
      imccImageResults={state.assetStatus.imccImage}
      searchBynder={searchBynder}
      searchBynderResults={state.assetStatus.searchBynder}
      searchExtApi={assetStatusSearchExtApi}
      searchExtApiResults={state.assetStatus.searchExtApi}
      usageRights={state.userSettings.usageRights}
      requestingMarket={state.userSettings.requestingMarket}
      purgeAkamaiCache={(idHash: string) => {
        return client.PurgeAkamaiCache({
          akamaiPurgeCache: { idHashList: [idHash] },
        });
      }}
      getVIMeta={(id: string) => {
        return client.GetVIAssetMeta({ vIAssetMetaPayload: { id } });
      }}
      getImageFeatures={(url) => {
        return client.VIImageFeatures({
          vIImageFeaturesPayload: { image: { imageUrl: url, type: 'imageUrl' } },
        });
      }}
      bynderUrl={bynder.BYNDER_BASE_URL}
      findSimilar={async (payload: FindSimilarPayload) => {
        return findSimilar(payload, client);
      }}
    />
  );

  const similaritySearchPage = (
    <SimilaritySearchPage
      bynderBaseUrl={bynder.BYNDER_BASE_URL}
      usageRights={state.userSettings.usageRights}
      requestingMarket={state.userSettings.requestingMarket}
      findSimilar={async (payload: FindSimilarPayload) => {
        const similar = await findSimilar(payload, client);

        if (!similar) {
          toastsApi.push({
            isError: true,
            message: 'Could not perform similarity search',
          });
          return false;
        }

        return similar;
      }}
      clipSearch={async (payload: ClipSearchPayload) => {
        const result = await clipSearch(payload, client);

        if (!result) {
          toastsApi.push({
            isError: true,
            message: 'Could not perform search',
          });
          return false;
        }

        return result;
      }}
    />
  );

  const cropPage = (
    <Crop
      bynderMeta={state.bynderMeta}
      loadBynderMeta={loadBynderMeta}
      uploadToBynder={createUploadAssetToBynderJob}
      loadPIAProducts={(itemIdentifiers: PIAItemIdentifier[]) => {
        return client.SearchPIABulk({
          pIABulkSearchRequestBody: { items: itemIdentifiers },
        });
      }}
      getUploadJobsByName={(name: string) => {
        return client.GetBynderAssetUploadJobList({
          bynderAssetUploadJobListRequestBody: {
            name,
          },
        });
      }}
    />
  );

  const deliveriesPage = (
    <Deliveries
      deliveries={state.deliveries.deliveriesList}
      loadDeliveries={loadDeliveries}
      loadMoreDeliveries={loadMoreDeliveries}
      loadDelivery={loadDelivery}
      deliveryDataMap={state.deliveries.deliveryDataMap}
      downloadPackage={triggerDownloadPackage}
      deliveryFSAssets={state.deliveries.deliveryFsAssets}
      loadDeliveryFSAssets={loadDeliveryFSAssets}
      uploadJobs={state.uploadsList}
      prepareForUpload={prepareForUpload}
    />
  );

  return (
    <>
      <SectionWrap WrapperElement="header">
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: left;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
          `}
        >
          <Link
            css={css`
              flex-shrink: 0;
            `}
            to="/"
          >
            <img
              src={logo}
              alt="logo"
              css={css`
                width: 90px;
                padding: 1.75rem 0;
              `}
            />
          </Link>
          <nav
            css={css`
              display: block;
              margin-left: 2rem;
              flex-grow: 1;
            `}
          >
            <ul
              css={css`
                display: flex;
                flex-direction: row;
                padding: 0;
                margin: 0;

                li {
                  list-style: none;

                  a {
                    display: block;
                    padding: 0.625rem 0.9375rem;
                    font-size: 0.875rem;
                    font-weight: 700;
                    color: #111;
                    text-decoration: none;
                    &:hover {
                      text-decoration: underline;
                    }

                    &.active {
                      text-decoration: underline;
                    }
                  }
                }
              `}
            >
              <li>
                <NavLink to="/" className={activeClass('active')}>
                  Home {env !== 'production' ? `(${String(env).toLowerCase()})` : ''}
                </NavLink>
              </li>
              {isDAMUser ? (
                <li>
                  <NavLink to="/deliveries" className={activeClass('active')}>
                    Deliveries
                  </NavLink>
                </li>
              ) : null}
              <li>
                <NavLink to="/phtools" className={activeClass('active')}>
                  PH-Tools
                </NavLink>
              </li>
              {isDAMUser ? (
                <li>
                  <NavLink to="/crop" className={activeClass('active')}>
                    Crop
                  </NavLink>
                </li>
              ) : null}
              {isDAMUser ? (
                <li>
                  <NavLink to="/assetsedit" className={activeClass('active')}>
                    Assets Edit
                  </NavLink>
                </li>
              ) : null}
              {isDAMUser ? (
                <li>
                  <NavLink to="/upload" className={activeClass('active')}>
                    Upload
                  </NavLink>
                </li>
              ) : null}
              <li>
                <NavLink to="/assetstatus" className={activeClass('active')}>
                  Asset status
                </NavLink>
              </li>
              <li>
                <NavLink to="/similarity-search" className={activeClass('active')}>
                  Similarity
                </NavLink>
              </li>
              <li>
                <NavLink to="/search/" className={activeClass('active')}>
                  Search
                </NavLink>
              </li>
              {isDAMUser ? (
                <li
                  css={css`
                    margin-left: auto;
                  `}
                >
                  <Button
                    ssrIcon={person}
                    iconOnly={true}
                    type={'tertiary'}
                    onClick={() => {
                      setShowProfile(!showProfile);
                    }}
                  />
                </li>
              ) : null}
            </ul>
          </nav>
        </div>
      </SectionWrap>
      {showProfile ? (
        <ProfileModal
          onClose={() => setShowProfile(false)}
          userSettings={state.userSettings}
          onUpdateUserSettings={(settings) => {
            dispatch({ type: 'update-user-settings', settings });
          }}
        />
      ) : null}

      <Routes>
        <Route path="/deliveries">
          <Route path="" element={deliveriesPage} />
          <Route path=":id" element={deliveriesPage} />
        </Route>
        <Route
          path="/phtools"
          element={
            <PHTools
              search={toolsSearch}
              searchFSResults={state.tools.searchFS}
              searchIDAMResults={state.tools.searchIDAM}
              downloadPackage={triggerDownloadPackage}
              prepareForUpload={prepareForUpload}
              isDamUser={isDAMUser}
            />
          }
        />
        <Route path="/assetstatus/">
          <Route path=":ph" element={assetStatus} />
          <Route path="" element={assetStatus} />
        </Route>

        <Route
          path="/upload"
          element={
            <Upload
              assetsToUpload={state.upload.jobs}
              bynderMeta={state.bynderMeta}
              loadBynderMeta={loadBynderMeta}
              uploadToBynder={createUploadAssetToBynderJob}
              loadUploadsList={loadUploadsList}
              uploadsList={state.uploadsList}
              bynderBaseUrl={bynder.BYNDER_BASE_URL}
              searchFS={uploadSearchFS}
              searchFSResult={state.upload.searchFS}
              setUploadJobItems={(items) => dispatch({ type: 'set-upload-jobs', jobs: items })}
              retryJob={retryUploadJob}
              retryResults={state.uploadRetryMap}
              searchIDAMImage={uploadSearchIDAMImage}
              searchIDAMImageResult={state.upload.searchIDAMImage}
              findSimilarImages={findSimilarImages}
            />
          }
        />
        <Route
          path="/assetsedit"
          element={
            <AssetEdit
              bynderMeta={state.bynderMeta}
              loadBynderMeta={loadBynderMeta}
              bynderBaseUrl={bynder.BYNDER_BASE_URL}
              editAssets={editBynderAsset}
              assets={state.edit.assets}
              assetsNameExt={state.edit.assetsExtName}
              fetchAssets={editSearchFsByIds}
              searchFsByExtName={searchFsByExtName}
              loadEditList={loadEditList}
              editList={state.edit.editList}
            />
          }
        />
        <Route
          path="/search/"
          element={
            <SearchPage
              results={state.search2.searchResult}
              search={search2ExtApi}
              usageRights={state.userSettings.usageRights}
              requestingMarket={state.userSettings.requestingMarket}
            />
          }
        />

        <Route path="/similarity-search">
          <Route path=":id" element={similaritySearchPage} />
          <Route path="" element={similaritySearchPage} />
        </Route>

        <Route path="/crop">
          <Route path=":id" element={cropPage} />
          <Route
            path=""
            element={
              <CropIndexPage
                results={state.searchCropPage.searchResult}
                search={searchCropPage}
                usageRights={state.userSettings.usageRights}
                requestingMarket={state.userSettings.requestingMarket}
              />
            }
          />
          <Route path="boards" element={<CropBoardsPage />} />
          <Route path="boards/:id" element={<CropBoardPage />} />
        </Route>
        <Route
          path="/"
          element={
            <Home>
              <div>
                <Section>
                  <div>Hello {account.name}!</div>
                </Section>
                <Button onClick={() => props.logout()}>Logout</Button>
              </div>
            </Home>
          }
        />
      </Routes>
      <Section style={{ marginBottom: '20vh' }}></Section>
    </>
  );
}

interface AuthWrapProps {
  pca: IPublicClientApplication;
}

const queryClient = new QueryClient();

interface ApiContextProps {
  getAccessToken: () => Promise<string>;
}

export const ApiContext = React.createContext<ApiContextProps>({
  getAccessToken: async () => {
    const p = new Promise<string>((resolve) => {
      resolve('');
    });
    return await p;
  },
});

function AuthWrap({ pca }: AuthWrapProps): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [account, setAccount] = useState<AccountInfo | null>(pca.getActiveAccount());
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const getAccessToken = async (): Promise<string> => {
    const silentRequest = {
      scopes: [ladanBackendScope],
      account: account ? account : undefined,
      forceRefresh: false,
    };

    const request = {
      scopes: [ladanBackendScope],
      loginHint: account?.username ? account.username : '',
    };

    const tokenResponse = await pca.acquireTokenSilent(silentRequest).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
        // fallback to interaction when silent call fails
        await pca.acquireTokenRedirect(request);
      }
      return null;
    });

    return tokenResponse?.accessToken ? tokenResponse.accessToken : '';
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ApiContext.Provider value={{ getAccessToken }}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MsalProvider instance={pca}>
          <App
            logout={async () => {
              await pca.logoutRedirect({
                onRedirectNavigate: (_) => {
                  // Return false if you would like to stop navigation after local logout
                  return false;
                },
              });

              window.location.reload();
            }}
          />
        </MsalProvider>
      </ApiContext.Provider>
    </QueryClientProvider>
  );
}

export default AuthWrap;
