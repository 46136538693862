import Checkbox from '@ingka/checkbox';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useImageSrc } from './hooks/idam';

export type ImageObject = {
  damUrl?: string;
  idamUrl?: string;
};

export interface ISelectableItemProps {
  id: string;
  selected: boolean;
  label: string;
  name: string;
  item: React.ReactNode;
  afterLabel?: React.ReactNode;
  imageUrl?: string;
  image?: ImageObject;
  onChange: (item: ISelectableItemProps) => void;
  archived?: boolean;
}

interface ISelectableListProps {
  localStorageKey: string;
  items: ISelectableItemProps[];
  onSelectAll: () => void;
}

function isAllSelected(list: ISelectableItemProps[]): boolean {
  if (list.length === 0) {
    return false;
  }

  for (const item of list) {
    if (item.selected === false) {
      return false;
    }
  }

  return true;
}

const SelectableListItem = (props: ISelectableItemProps & { showImage: boolean }) => {
  const { id, item, onChange, selected, label, image, imageUrl } = props;

  const src = useImageSrc(image || { damUrl: imageUrl });

  return (
    <div
      css={css`
        display: flex;
        padding: 10px;
        transition: all 0.15s ease-out;

        &:hover {
          box-shadow: 0px 2px 7px -5px #000;
        }
      `}
    >
      {props.showImage ? (
        <img
          src={src}
          css={css`
            width: 100px;
            height: 100px;
            object-fit: contain;
          `}
        />
      ) : null}

      <Checkbox
        id={id}
        label={label}
        checked={selected}
        value={''}
        onChange={() => onChange(props)}
        css={css`
          align-self: center;
          margin-left: ${props.showImage ? '15px' : '0px'};
        `}
      />
      {props.afterLabel ? props.afterLabel : null}

      <div
        css={css`
          margin-left: auto;
          display: flex;
          align-items: center;
          max-width: 40%;
        `}
      >
        {item}
      </div>
    </div>
  );
};

export const SelectableList = (props: ISelectableListProps): React.ReactElement => {
  const allSelected = isAllSelected(props.items);
  const [showImage, setShowImage] = React.useState(() => {
    return localStorage.getItem(props.localStorageKey) === 'true';
  });

  return (
    <>
      <div>
        <Checkbox
          id={'Show images'}
          label={<b>Show images</b>}
          value={''}
          onChange={() => {
            setShowImage(!showImage);
            localStorage.setItem(props.localStorageKey, JSON.stringify(!showImage));
          }}
          checked={showImage}
          css={css`
            margin-bottom: 1.5rem;
            padding: 10px;
          `}
        />
        <Checkbox
          id={'select_all'}
          label={<b>Select all</b>}
          value={''}
          onChange={props.onSelectAll}
          checked={allSelected}
          css={css`
            margin-bottom: 1.5rem;
            padding: 10px;
          `}
        />
      </div>
      <div>
        {props.items.map((i) => (
          <SelectableListItem key={i.id} {...i} showImage={showImage} />
        ))}
      </div>
    </>
  );
};
